import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import LatestImage from './LatestImage';
import Archive from './Archive';

function App() {
    const navStyle = {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        backgroundColor: '#333',
        textAlign: 'center'
    };

    const linkStyle = {
        display: 'inline-block',
        color: 'white',
        textAlign: 'center',
        padding: '14px 16px',
        textDecoration: 'none'
    };

    return (
        <Router>
            <div>
                <nav>
                    <ul style={navStyle}>
                        <li style={{ float: 'left' }}>
                            <Link to="/" style={linkStyle}>Latest</Link>
                        </li>
                        <li style={{ float: 'left' }}>
                            <Link to="/archive" style={linkStyle}>Archive</Link>
                        </li>
                    </ul>
                </nav>

                <Routes>
                    <Route path="/" element={<LatestImage />} />
                    <Route path="/archive" element={<Archive />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
