import React, { useEffect, useState } from 'react';
import { parseTimestamp } from './utils'; // Import the parseTimestamp function
import './LatestImage.css';

function LatestImage() {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000'; // Fallback to localhost if env var is not set
    const [imageUrl, setImageUrl] = useState('');
    const [imageTimestamp, setImageTimestamp] = useState('');

    // Store the original URL
    const [originalImageUrl, setOriginalImageUrl] = useState('');

    useEffect(() => {
        fetch(`${apiUrl}/latest`)
            .then(response => response.json())
            .then(data => {
              // Construct the CDN URL with resizing parameter
              const cdnUrl = data.imageUrl.replace('https://haczod-folnes.nyc3.digitaloceanspaces.com', 'https://ccnuctxaga.cloudimg.io/haczod-folnes.nyc3.digitaloceanspaces.com') + '?width=1200';
              setImageUrl(cdnUrl);
              setOriginalImageUrl(data.imageUrl); // Store the original URL
              setImageTimestamp(parseTimestamp(data.imageUrl.split('/').pop()));
            })
            .catch(error => console.error('Error fetching the latest image:', error));
    }, []);

    return (
        <div className="latest-image-container">
            <h2 className="latest-image-title">GOES-16 Viewer</h2>
            {imageUrl && <img src={imageUrl} alt="Latest Satellite Image" style={{ maxWidth: '100%' }} />}
            {imageTimestamp && <p>{imageTimestamp} CST</p>} {/* Conditionally display the timestamp and CST */}
            {originalImageUrl && (
                        <p className="download-button"><a href={originalImageUrl} download target="_blank" rel="noopener noreferrer">
                            Download Full Image
                        </a>
                        </p>
                    )}
        </div>
    );
}

export default LatestImage;
