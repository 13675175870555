import React, { useState,useEffect } from 'react';
import { parseTimestamp } from './utils'; // Import the parseTimestamp function
import DatePicker from 'react-datepicker'; // We use datepicker to conditionally enable dates for selection
import 'react-datepicker/dist/react-datepicker.css';
import './Archive.css'; // Import the CSS file


function Archive() {
    const [availableDates, setAvailableDates] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');
    const [selectedImageTimestamp, setSelectedImageTimestamp] = useState('');

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000'; // Fallback to localhost if env var is not set
        fetch(`${apiUrl}/available-dates`) // the endpoint API for available dates
            .then(response => response.json())
            .then(data => setAvailableDates(data))
            .catch(error => console.error('Error fetching available dates:', error));
    }, []);

    // Custom input component for the date picker
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button className="date-picker-button" onClick={onClick} ref={ref}>
            {value || "Select a Date"}
        </button>
    ));

    const handleDateChange = (date) => {
        // Convert date to YYYY-MM-DD format
        const formattedDate = date.toISOString().split('T')[0];
        setSelectedImageUrl(''); // Reset the selected image
        setImageUrls([]); // Clear the list of image URLs
        setCurrentIndex(null); // Reset the current index

        const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000'; // Fallback to localhost if env var is not set

        fetch(`${apiUrl}/archive/${formattedDate}`)
            .then(response => response.json())
            .then(data => setImageUrls(data.imageUrls))
            .catch(error => console.error('Error fetching images:', error));
    };


    // Function to determine if a date should be enabled for selection (i.e. has images available)
    const isDateAvailable = (date) => {
        const formattedDate = date.toISOString().split('T')[0];
        return availableDates.includes(formattedDate);
    };

    // Provide the ability to go forwards/backwards in time
    const [currentIndex, setCurrentIndex] = useState(null);

    // Helper function to transform URL to CDN URL
    const transformToCdnUrl = (url) => {
        return url.replace('https://haczod-folnes.nyc3.digitaloceanspaces.com', 'https://ccnuctxaga.cloudimg.io/haczod-folnes.nyc3.digitaloceanspaces.com') + '?width=1200';
    };

    // Store the original URL
    const [originalImageUrl, setOriginalImageUrl] = useState('');

    const goToPrevious = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            const prevUrl = transformToCdnUrl(imageUrls[newIndex]);
            setSelectedImageUrl(prevUrl);
            setSelectedImageTimestamp(parseTimestamp(imageUrls[newIndex].split('/').pop()) + ' CST');
            setCurrentIndex(newIndex);
        }
    };

    const goToNext = () => {
        if (currentIndex < imageUrls.length - 1) {
            const newIndex = currentIndex + 1;
            const nextUrl = transformToCdnUrl(imageUrls[newIndex]);
            setSelectedImageUrl(nextUrl);
            setSelectedImageTimestamp(parseTimestamp(imageUrls[newIndex].split('/').pop()) + ' CST');
            setCurrentIndex(newIndex);
        }
    };

    // Function to handle when an image button is clicked (i.e. set the selected image and timestamp to display)
    const handleImageClick = (url, index) => {
        const cdnUrl = transformToCdnUrl(url);
        setSelectedImageUrl(cdnUrl);
        setOriginalImageUrl(url); // Store the original URL
        setSelectedImageTimestamp(parseTimestamp(url.split('/').pop()) + ' CST');
        setCurrentIndex(index);
    };

    // Render the component
    return (
        <div className="archive-image-container">
            <div className="date-picker-container">
                <DatePicker
                    onChange={handleDateChange}
                    filterDate={isDateAvailable}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInput />}
                />
            </div>
            {!selectedImageUrl && ( // Conditionally render the image button container
                <div className="image-button-container">
                    {imageUrls.map((url, index) => (
                        <button key={url} onClick={() => handleImageClick(url, index)} className="image-button">
                            View Image {parseTimestamp(url.split('/').pop())}
                        </button>
                    ))}
                </div>
            )}
            {selectedImageUrl && (
                <>
                    <img
                        key={`${selectedImageUrl}-${currentIndex}`}
                        src={selectedImageUrl}
                        alt="Satellite View"
                        className="archive-image"
                    />
                    {selectedImageTimestamp && <p className="archive-timestamp">{selectedImageTimestamp}</p>}
                    {originalImageUrl && (
                        <p className="download-button"><a href={originalImageUrl} download target="_blank" rel="noopener noreferrer">
                            Download Full Image
                        </a>
                        </p>
                    )}
                    <div className="navigation-buttons">
                        <button onClick={goToPrevious} disabled={currentIndex <= 0}>Previous</button>
                        <button onClick={goToNext} disabled={currentIndex >= imageUrls.length - 1}>Next</button>
                    </div>
                </>
            )}
        </div>
    );
}

export default Archive;
